/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"

import Header from "./header"
import Nav from './nav';

import { FaTwitter, FaPatreon } from 'react-icons/fa';

const GlobalStyle = createGlobalStyle`
    :root {
        --accent-color: #b36720;
    }
`

const BodyContainer = styled.div`
    margin: 0 auto;
`

const Centered = styled.div`
    text-align: center;
    padding: 10px;
    a {
        margin: 8px;
    }
`

const Footer = () => {
    return (
        <Centered>
            {'Copyright © Skyebold all rights reserved.'}
            <br/>
            <a href="https://twitter.com/koboldskye"><FaTwitter title="Twitter @KoboldSkye"/></a>
            <a href="https://www.patreon.com/skyebold"><FaPatreon title="SkyeBold Patreon"/></a>
        </Centered>
    );
}

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyle/>
            <Header/>
            <Nav/>
            <BodyContainer>
                {children}
            </BodyContainer>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
