import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BannerImage = props => {
    const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Img 
            fluid={data.banner.childImageSharp.fluid} 
            fadeIn={false}
        />
    )
};


export default BannerImage;
