import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';

import NavElement from './nav_element';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0 12px;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: lightgrey;
`;

const Nav = props => {
  const { config } = useStaticQuery(
    graphql`
    {
        config: configJson {
            nav {
                title
                to
            }
        }
    }
    `
  )

    return (
        <Container>
            {config.nav.map((nav) => (
                <NavElement
                    key={nav.title}
                    title={nav.title}
                    to={nav.to}
                />
            ))}
        </Container>
    );
};

export default Nav;
