import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

const Container = styled.div`
    flex-wrap: wrap;
    flex-basis: auto;
    padding: 10px;
    a {
        text-decoration: none;
        color: #606060;
        font-size: 1.2rem;
        font-weight: 400;
        background-image: none;
    }
    a:hover {
        color: #bfa2a9;
    }
`;

const NavElement = props => {
    return (
        <Container>
            <Link to={props.to}>
                {props.title}
            </Link>
        </Container>
    );
};

export default NavElement;
