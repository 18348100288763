import React from "react"
import styled from 'styled-components';
import {Link} from 'gatsby';

import BannerImage from './banner_image';

const Banner = styled.div`
    padding: 10px;
`

const Container = styled.div`
    margin: 0 auto;
    max-width: 1000px;
`

const Header = ({ siteTitle }) => (
  <Container>
      <Banner>
          <Link to='/'>
            <BannerImage />
          </Link>
      </Banner>
  </Container>
)

export default Header
